var scrollpfeile = document.querySelectorAll('.ufe');
if (typeof scrollpfeile != 'undefined') {
    scrollpfeile.forEach(scrollpfeil => {
        scrollpfeil.addEventListener('click', function (event) {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
        });
    })
}