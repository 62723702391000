var menueAktiv = false;
var seite = document.getElementById('seite');
var hamburger = document.getElementById('hamburger')

// Alle Elemente finden, die tatsächlich sticky sind
var stickyElemente = [];
function stickyElementeFinden() {
    var stickyElementeAlle = document.querySelectorAll('[data-sticky]');
    if (stickyElementeAlle) {
        for (let i = 0; i < stickyElementeAlle.length; i++) {
            if (window.getComputedStyle(stickyElementeAlle[i]).position == 'sticky') {
                stickyElemente.push(stickyElementeAlle[i]);
            }
        }
    }
}
stickyElementeFinden();

var y = 0;
const stickyElementeArray = [];
const nebeninformationDesktop = document.getElementById('projekt__nebeninformation--desktop');
function menueToggle() {
    if (menueAktiv == false) {
        // Scrolltrigger anhalten, bevor die Seite fixiert wird
        ScrollTrigger.getAll().forEach(element => element.disable(false));
        if (typeof scrolltrigger != 'undefined') {
            scrolltrigger.disable(false);
        }
        if (typeof stickyElemente != 'undefined') {
            for (let i = 0; i < stickyElemente.length; i++) {
                var stickyElementStyles = window.getComputedStyle(stickyElemente[i]);
                stickyElementeArray[i] = [];
                stickyElementeArray[i].top = stickyElementStyles.top;
                
                var stickyElementTop = stickyElemente[i].getBoundingClientRect().top;
                var stickyElementWidth = stickyElemente[i].getBoundingClientRect().width;
                stickyElemente[i].style.top = stickyElementTop + 'px';
                stickyElemente[i].style.width = stickyElementWidth + 'px';
                stickyElemente[i].style.position = 'fixed';
            }
        }
        menueAktiv = true;
        document.body.addEventListener('click', menueBodyClick);
        seite.addEventListener('mouseenter', menueSeiteHover);
        seite.addEventListener('mouseleave', menueSeiteHover);
        
        scrollbar = window.innerWidth - document.documentElement.clientWidth;
        y = window.scrollY;
        document.body.setAttribute('data-menue-aktiv', menueAktiv);
        var marginOriginal = parseFloat(window.getComputedStyle(hamburger).marginRight);
        hamburger.style.marginRight = marginOriginal + scrollbar + 'px';
        document.body.style.paddingRight = scrollbar + 'px';
        document.body.style.top = -y + 'px';
    } else {
        if (typeof stickyElemente != 'undefined') {
            for (let i = 0; i < stickyElemente.length; i++) {
                stickyElemente[i].style.top = stickyElementeArray[i].top;
                stickyElemente[i].style.position = 'sticky';
            }
        }
        menueAktiv = false;
        document.body.removeEventListener('click', menueBodyClick);
        seite.removeEventListener('mouseenter', menueSeiteHover);
        seite.removeEventListener('mouseleave', menueSeiteHover);
        document.body.setAttribute('data-menue-aktiv', menueAktiv);
        menueSeiteHover(false);
        hamburger.style.marginRight = '';
        document.body.style.paddingRight = '';
        document.body.style.top = '';
        window.scrollTo(0, y);
        ScrollTrigger.getAll().forEach(element => element.enable(false));
    }
}

function menueBodyClick(event) {
    if (menueAktiv == true) {
        if (!event.target.closest('.header__menue')) {
            menueToggle();
        }
    }
}

function menueSeiteHover(event) {
    if (event.type == 'mouseenter') {
        document.body.setAttribute('data-seite-hover', 'true');
    } else {
        document.body.setAttribute('data-seite-hover', 'false');
    }
}

// Hamburgermenü aktivieren
document.getElementById('hamburger').addEventListener('click', event => {
    event.preventDefault();
    event.stopPropagation();
    menueToggle();
});

var menue = document.getElementById('header__menue');
menue.addEventListener('click', event => {
    if (menueAktiv == true) {
        const { target } = event;
        if (target.matches('.header__link')) {
            menueToggle();
        }
    }
});